<template>
  <v-container>
    <div class="impressum-container">
      <h1>Impressum</h1>
      <div>
        Supertracker GmbH <br/>
        Zellerberg 2/888, 6330 Kufstein <br/>
        Austria <br/>
        E-Mail: <a href="mailto:office@supertracker.eu">office@supertracker.eu</a> <br/> <br/>

        UID Nr: ATU71325639<br/> <br/>

        <h4>Haftungsausschluss (Disclaimer)</h4>
        <h4>Haftung für Inhalte</h4><br/>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
        übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
        eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
        erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. <br/><br/>

        <h4>Haftung für Links</h4>

        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
        zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
        der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
        konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Links umgehend entfernen. <br/><br/>

        <h4>Urheberrecht</h4>

        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
        die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
        Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br/><br/>

        Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:<br/>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die du unter
        <a href="https://ec.europa.eu/consumers/odr" target="_blank">https://ec.europa.eu/consumers/odr</a> findest.
        Wir sind bereit, an einem außergerichtlichen Schlichtungsverfahren teilzunehmen.<br/>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Impressum"
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/variables.scss";
  @import "../assets/scss/layout.scss";
  .impressum-container {
    color: #444444;
    font-weight: 300;
    position: relative;
    padding: 150px 100px 50px;
    @include MQ(XS) {
      padding: 100px 10px 25px;
    }
    @include MQ(M) {
      padding: 150px 50px 50px;
    }
    @include MQ(S) {
      padding: 150px 50px 50px;
    }
    h1 {
      font-weight: 400;
      font-size: 45px;
      line-height: 48px;
      padding-bottom: 25px;
      @include MQ(XS) {
        text-align: left;
        font-size: 3.2vh;
        line-height: 3.4vh;
      }
      @include MQ(S) {
        font-size: 34px;
        line-height: 37px;
      }
      @include MQ(L) {
        font-size: 40px;
        line-height: 43px;
      }
      @include MQ(M) {
        font-size: 40px;
        line-height: 43px;
      }
    }
    a {
      text-decoration: none;
      color: #444444;
    }
  }
</style>
